<script>
import { required, minLength } from "vuelidate/lib/validators";
import Customers from '@/services/Customers';
import validationMessages from '@/components/validations'

export default {

  components:{
    validationMessages
  },
  data() {
    return {
      csrf_token: localStorage.getItem('csrf_token'),
      source: 'dashboard',
      showModal: false,
      tryingToEdit: false,
      submitted: false,
      name : '' ,
      description : '' ,
      type: 'static',
    };
  },
  validations : {
    name : {
      required,
      minLength: minLength(2)
    },
  },
  methods: {

    async addNewGroup() {
      this.tryingToEdit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToEdit = false;
        return;
      }

      try {
        await Customers.addGroup({
          name: this.name,
          description: this.description,
          type: this.type,
        });

        this.successmsg("Group is successfully added");
        this.closeModal();
        this.refreshData();
      } catch (error) {
        this.error = error.response.data.error ? error.response.data.error : "";
        this.failedmsg(this.error);
      } finally {
        this.tryingToEdit = false;
      }
    },

    resetProps(){
      this.name= ''
      this.description= ''
      this.submitted  = false
      this.tryingToEdit  = false

    },
    refreshData() {
      this.$emit('onRefresh')
    },
    closeModal() {
      this.showModal = false;

    },
  }
};
</script>
<template>
  <b-modal v-model="showModal" id="add_groups" @hidden='resetProps()' title="Add Group" title-class="font-18" centered>
    <form @submit.prevent="addNewGroup">
      <b-form-group label="Name" label-for="formrow-name-input" class="mb-3">
        <b-form-input
            v-model.trim="name"
            type="text"
            id="name"
            aria-describedby="name-feedback"
            :class="{
                                          'is-invalid': submitted && $v.name.$error,
                                        }"
        >
        </b-form-input>
        <validationMessages v-if="submitted" :fieldName="'Name'" :validationName="$v.name"></validationMessages>

      </b-form-group>
      <b-form-group label="Description" label-for="formrow-description-input" class="mb-3">
        <b-form-input
            v-model.trim="description"
            type="text"
            id="description"
            aria-describedby="name-feedback">
        </b-form-input>
      </b-form-group>
      <input type="hidden" name="csrf_token" v-model="csrf_token">
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="addNewGroup" :disabled="tryingToEdit">
        <b-spinner v-show="tryingToEdit" small></b-spinner>
        Submit</b-button>
    </template>
  </b-modal>
</template>