<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Customers from '@/services/Customers'
import Swal from "sweetalert2";
import store from "@/state/store";
import modalAddGroups from '@/components/modals/customers/modalAddGroups';


export default {
  components: {Layout, PageHeader, modalAddGroups},
  page: {
    title: "Groups",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Groups",
      items: [
        {
          text: "Users",
        },
        {
          text: "Groups",
          active: true,
          href: "/customers/groups",
        },
      ],
      csrf_token: localStorage.getItem('csrf_token'),
      isBusy: false,
      error: null,
      showModal: false,
      modalData: {},
      link_id: null,
      tableData: [],
      totalRowsGroup: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "timestamp",
      sortDesc: true,
      showLoader: false,
      showHistory: false,
      groupsData: [],
      group_id : {} ,
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "provider",
          label: "Provider",
          sortable: true,
        },
        {
          key: "created_by",
          label: "Created By",
          sortable: true,
        },
        "action",
      ],
    };
  },
  async created() {
    await this.getGroups()
  },
  methods: {
    async getGroups() {
      this.showLoader = true;
      let showHistory = this.showHistory == true ? 1 : 0;
      try {
        this.toggleBusy();
        const response = await Customers.getGroups('showHistory=' + showHistory)
        this.tableData = response.data.data
        this.totalRowsGroup = this.tableData.length
        this.toggleBusy();
      } catch (error) {
        this.toggleBusy();
        this.error = error.response.data.error ? error.response.data.error : "";
        this.tableData = []
        this.totalRowsGroup = 0;
      } finally {
        this.showLoader = false;
      }
      const initialtotalRowsGroup = this.tableData.length;
      this.$store.commit('settotalRowsGroup', initialtotalRowsGroup);
    },
    callModalAddGroups(data) {
      this.groupsData = data;
      this.$bvModal.show("add_groups");
    },

    onFiltered(filteredItems) {
      this.totalRowsGroup = filteredItems.length;
      this.currentPage = 1;
    },

  },
  middleware: "authentication",
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <div class="row" v-if="!showLoader">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="col-12 col-md-12  float-start">
              <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
                 @click="callModalAddGroups()">
                <i class="mdi mdi-plus me-2"></i>Add Groups
              </a>
            </div>
            <div class="row">
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="mb-0">
              <b-table
                  :busy="isBusy"
                  :items="tableData"
                  :fields="fields"
                  responsive
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  show-empty
                  empty-text="No Data Found"
                  @filtered="onFiltered"
              >
                <!-- Custom cell template for action column -->
                <template #cell(action)="data">
                  <router-link
                      :to="{ path: `/customers/group_details/${data.item.group_id}` }"
                      title="View Group"
                      class="px-2 text-blue"
                      v-b-tooltip.hover
                  >
                    <i class="uil uil-eye font-size-18"></i>
                  </router-link>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRowsGroup"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card-body d-flex align-items-center justify-content-center" >
        <b-spinner large></b-spinner>
      </div>
    </div>
    <modalAddGroups @onRefresh="getGroups"></modalAddGroups>

  </Layout>
</template>